import type { AsyncData } from "#app/composables/asyncData";
import { UsersApi } from "@simbelapp/auth-sdk";
import { usePagination } from "../paginations/usePagination";
import { useFilters } from "../filters/useFilters";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { useAuthStore } from "~/store/auth/auth.store";
import type {
  IAddUserToGroupsPayload,
  ICompanyUserFullDatas,
  ICompanyUsers,
  ICreateUserPayload,
  IDisableUserPayload,
  INotifyUserPayload,
  IUpdateGroupUserRolePayload,
  IUpdateUserPayload,
} from "~~/utils/interfaces/users-interfaces";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import type { IPaginationApi } from "~/utils/interfaces/table-interfaces";

export const useUsersApi = () => {
  // Minimal amount of infos
  async function fetchCompanyUsers(
    enableRolesFilters = true,
    lazyLoad = false,
    search?: string,
    usersIds?: string[],
  ): Promise<ICompanyUsers[] | IPaginationApi<ICompanyUsers>> {
    const apiInstance = await useSDKApi(MicroserviceEnum.USERS, UsersApi);

    let filters = null;
    if (usersIds?.length) {
      filters = `filters=${encodeURIComponent(`user_ids=${usersIds.map((x) => encodeURIComponent(x)).join(",")}`)}`;
    }
    const queryParams = `enableRolesFilters=${enableRolesFilters}&lazyLoad=${lazyLoad}&${filters || ""}&search=${
      search || ""
    }`;

    const paginate_query = new URLSearchParams(queryParams);
    const query_string = Object.fromEntries(paginate_query.entries());

    const response = await apiInstance.getUsersOfCompanyRaw({
      ...query_string,
    });
    return await response.raw.json();
  }

  async function fetchCompanyUsersManger(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/companyUsersManager`, {
      method: "GET",
    });
  }

  async function fetchCompanyUsersGroupAdmin(): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/companyUsersGroupAdmin`, {
      method: "GET",
    });
  }

  async function exportUsers(format: string): Promise<void> {
    const apiInstance = await useSDKApi("auth", UsersApi);
    await apiInstance.exportUsers({
      format,
    });
  }

  // All users infos
  async function fetchCompanyUsersFullDatas(): Promise<IPaginationApi<ICompanyUserFullDatas>> {
    const pagination = usePagination();
    const filters = useFilters();
    const apiInstance = await useSDKApi(MicroserviceEnum.USERS, UsersApi);

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    const response = await apiInstance.getCollaboratorsRaw({
      ...query_string,
      ...filters_query,
      sortingColumn: paginate_options.sorting_column,
      excludeDisabledUsers: "false",
    });
    return await response.raw.json();
  }

  async function fetchUserDetails(user_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/${user_id}`, {
      method: "GET",
    });
  }

  async function fetchUserGroups(user_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/groups/getUserGroups/${user_id}`, {
      method: "GET",
    });
  }

  async function fetchNotExcludedUser(userIdList: string[]): Promise<AsyncData<any, any>> {
    const filters = useFilters();

    const filters_query = filters.getFiltersPayload();
    const query_string = [filters_query].filter(Boolean).join("&");

    return await useFetchApi("auth", `/users/getUsersNotExcluded?${query_string}`, {
      method: "GET",
      query: {
        usersList: userIdList,
      },
    });
  }

  async function createUser(createUserPayload: ICreateUserPayload): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users`, {
      method: "POST",
      body: createUserPayload,
    });
  }

  async function updateUser(updateUserPayload: IUpdateUserPayload, userId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/${userId}`, {
      method: "PATCH",
      body: updateUserPayload,
    });
  }

  async function updateUserGroupRole(
    updateGroupUserRolePayload: IUpdateGroupUserRolePayload,
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/groups/updateUserGroupRole`, {
      method: "PATCH",
      body: updateGroupUserRolePayload,
    });
  }

  async function addUserToGroups(addUserToGroupsPayload: IAddUserToGroupsPayload): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/groups/addUsersToGroups`, {
      method: "PATCH",
      body: addUserToGroupsPayload,
    });
  }

  async function setUserLanguage(language: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/change-language`, {
      method: "PATCH",
      body: {
        language,
      },
    });
  }

  async function disableUser(disableUserPayload: IDisableUserPayload): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/disable`, {
      method: "PATCH",
      body: disableUserPayload,
    });
  }

  async function notifyUsers(notifyUserPayload: INotifyUserPayload): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/notify-users`, {
      method: "POST",
      body: notifyUserPayload,
    });
  }

  async function updateNames(usernamePayload: { firstname: string; lastname: string }): Promise<AsyncData<any, any>> {
    return await useFetchApi("auth", `/users/name`, {
      method: "PATCH",
      body: usernamePayload,
    });
  }

  async function fetchAdminAllUsers(): Promise<AsyncData<any, any>> {
    const authStore = useAuthStore();
    const pagination = usePagination();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();

    return await useFetchApi("auth", `/admin/users?${paginate_query}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authStore.tokens?.refresh_token}`,
      },
      // pick: [
      //     "user_id",
      //     "email",
      //     "fullname",
      //     "company_name",
      //     "roles",
      // ]
    });
  }

  async function disableSSO(
    disable: boolean,
    selectAll: boolean,
    trainingIds: string[],
    excludedIds?: string[],
  ): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const filters_query = filters.getFiltersPayload();
    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    const paginate_query = new URLSearchParams(paginate_options).toString();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("auth", `/users/disable-sso?${query_string}`, {
      body: { disable, selectedIds: trainingIds, excludedIds, selectAll },
      method: "PATCH",
    });
  }

  return {
    exportUsers,
    disableUser,
    addUserToGroups,
    updateUserGroupRole,
    fetchCompanyUsers,
    fetchUserDetails,
    setUserLanguage,
    fetchCompanyUsersFullDatas,
    createUser,
    fetchUserGroups,
    fetchNotExcludedUser,
    updateUser,
    notifyUsers,
    updateNames,
    fetchAdminAllUsers,
    fetchCompanyUsersManger,
    fetchCompanyUsersGroupAdmin,
    disableSSO,
  };
};
